import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';

export const AppHeader : React.FC<{title : string}> = ({title}) => {
    return <><Navbar className="header" bg="dark" variant="dark">
    <Navbar.Brand href="#home">{title}</Navbar.Brand>
    <Nav className="mr-auto">
    </Nav>
  </Navbar></>
}

export default AppHeader;