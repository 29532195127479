import React, { Component, FunctionComponent, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  Redirect
} from "react-router-dom";
import MemberApp from './components/members/MemberApp';
import './app.scss';
import OverlayProvider, { useOverlay } from './provider/overlay.provider';
import AppHeader from './components/AppHeader';
import AppBody from './components/AppBody';
import GroupApp from './components/groups/GroupApp';
import Keycloak from 'keycloak-js';

const OldSchoolMenuLink : React.FunctionComponent<{label : string, to : string, activeOnlyWhenExact : boolean}> = ({ label, to, activeOnlyWhenExact }) => {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  return (
    <div className={match ? "active" : ""}>
      {match && "> "}
      <Link to={to}>{label}</Link>
    </div>
  );
}

const HeaderBar : FunctionComponent<{title : string}> = ({title}) => {
  return <div className="header">
    <div className="title">{title}</div>
  </div>
}

const InlineOverlay = () => {
  const {isHidden} = useOverlay();
  return <>{!isHidden && <div className="inline-overlay"></div>}</>;
}

const Secured = () => {
  const [keycloak, setKeycloak] = useState<Keycloak.KeycloakInstance>();
  const [authenticated, setAutheticated] = useState<boolean>(false);

  useEffect(() => {
    const keycloak = Keycloak('/keycloak.json');
    keycloak.init({onLoad: 'login-required'}).then(authenticated => {
      setKeycloak(keycloak);
      setAutheticated(authenticated);

      keycloak.loadUserInfo().then(userInfo => {
        console.log(userInfo);
      });

      console.log(keycloak.tokenParsed);
    })
  }, []);

  return <>
    {keycloak && authenticated && <>Secured</>}
    {keycloak && !authenticated && <>Unable to authenticate!</>}
    {!keycloak && <>Initializing Keycloak...</>}
  </>
}

const App = () => {
  return <>
    <OverlayProvider>
      <InlineOverlay />
      <div className="feuerwehr-app">
        <AppHeader title={"Feuerwehr Betzingen"} />
        <Router>
          <Switch>
            <Route exact path="/">
              <OldSchoolMenuLink
                activeOnlyWhenExact={true}
                to="/member"
                label="Member"
              />
              <OldSchoolMenuLink
                activeOnlyWhenExact={true}
                to="/group"
                label="Groups"
              />
              <OldSchoolMenuLink
                activeOnlyWhenExact={true}
                to="/sec"
                label="Secure"
              />
            </Route>
            <Route exact path="/member">
              <MemberApp />
            </Route>
            <Route exact path="/sec">
              <Secured />
            </Route>
            <Route exact path="/member/:id">
              <MemberApp />
            </Route>
            <Route exact path="/group">
              <GroupApp />
            </Route>
            <Route render={() => <Redirect to="/"/>}/>
          </Switch>
        </Router>
      </div>
    </OverlayProvider>
  </>
}

export default App;
