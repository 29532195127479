import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUser, faCog } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react';
import { Accordion, Badge, Button, Card } from 'react-bootstrap';
import AppBody from '../AppBody';
import groupService, { 
    GroupInfo,
    GroupInfos} from '../../services/group.service';
import { useOverlay } from '../../provider/overlay.provider';
import GroupMembersPopup from './GroupMembersPopup';

const GeneralInformationCard : React.FC<{group : GroupInfo}> = ({group}) => {
    const { setFocusedElement, isFocusOnElement } = useOverlay();
    const [editMode, setEditMode] = useState<boolean>(false);

    useEffect(() => {
        if (editMode) {
            setFocusedElement(`general-info-${group.type}-${group.data.id}`)
        } else {
            setFocusedElement('');
        }
    }, [editMode])

    const cancel = () => {
        setEditMode(false);
    }

    const save = () => {

        setEditMode(false);
    }

    return <Card 
            bg="dark" 
            className={"mr-1 " + (isFocusOnElement(`general-info-${group.type}-${group.data.id}`) ? "inline-overlay-focus-element" : "")} 
            style={{ color: '#fff' }}>
        <Card.Body>
            <Card.Title className="d-flex flex-row">
                <div className="d-flex justify-content-center mr-auto">Allgemein</div>
                {!editMode && <Button variant="dark" className="d-flex justify-content-center" onClick={() => setEditMode(true)}><FontAwesomeIcon icon={faCog} color="white"/></Button>}
            </Card.Title>
            <Card.Text>
                {!editMode && <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between"><span className="mr-2">Name:</span> {group.data.name}</div>
                    {(group.type === 'main' || group.type === 'extended-main') && 
                        <div className="d-flex justify-content-between"><span className="mr-2">Farbe:</span> {group.data.color}</div>}
                </div>}
                {editMode && <>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Name:</span>
                        </div>
                        <input type="text" className="form-control" placeholder="Name" value={group.data.name} />
                    </div>
                    {(group.type === 'main' || group.type === 'extended-main') && <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Color:</span>
                        </div>
                        <input type="color" className="form-control" placeholder="Farbe" value={group.data.color} />
                    </div>}
                    <div className="btn-group" role="group">
                        <button className="btn btn-success" type="button" onClick={() => save()}>Speichern</button>
                        <button className="btn btn-danger" type="button" onClick={() => cancel()}>Abbrechen</button>
                    </div>
                </>}
            </Card.Text>
        </Card.Body>
    </Card>
}

const AllInklRedirection : React.FC<{group : GroupInfo}> = ({group}) => {
    return <Card bg="dark" style={{ color: '#fff' }}>
        <Card.Body>
            <Card.Title className="d-flex flex-row">
                <div className="d-flex justify-content-center mr-auto">All-Inkl - Weiterleitung</div>
                <Button variant="dark" className="d-flex justify-content-center"><FontAwesomeIcon icon={faCog} color="white"/></Button>
            </Card.Title>
            <Card.Text>
                <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between"><span className="mr-2">E-Mail: </span>weiterleitung@feuerwehr-betzingen.de</div>
                    <div className="d-flex justify-content-between"><span className="mr-2">Adressen: </span>5</div>
                </div>
            </Card.Text>
        </Card.Body>
    </Card>
}

interface BaseGroupProps {
    eventKey: string;
    group : GroupInfo;
}

const BaseGroupCard : React.FC<BaseGroupProps> = ({eventKey, group, children}) => {
    const [editMembers, setEditMembers] = useState<boolean>(false);

    return <><Card>
            <Card.Header>
                <div className="d-flex">
                    <div className="d-flex mr-auto align-items-center">
                    <Accordion.Toggle as={Button} variant="link" eventKey={eventKey}>
                        <FontAwesomeIcon icon={faUsers} color="black" className="mr-1"/> {group.data.name} 
                    </Accordion.Toggle>
                    </div>
                    { (group.type === 'extended-main' || group.type === 'extended-sub') && <Button variant="primary" size="sm" onClick={() => setEditMembers(true)}>
                        <FontAwesomeIcon icon={faUser} color="white"/> Mitglider <Badge variant="light">{group.data.memberCount}</Badge>
                        <span className="sr-only">unread messages</span>
                    </Button> }
                </div>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body className="d-flex flex-row">
                    {children}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
        {editMembers && <GroupMembersPopup currentGroup={group} show={editMembers} setShow={setEditMembers} />}
    </>
}

interface BaseGroupListProps {
    groups : GroupInfo[];
}

const BaseGroupList : React.FC<BaseGroupListProps> = ({groups}) => {
    return <div className="group-list">
        <Accordion className="w-100">
            {groups.map((group, idx) => {
                return <BaseGroupCard key={'main-group-' + group.data.id} group={group} eventKey={idx.toString()}>
                        <GeneralInformationCard group={group} />
                        <AllInklRedirection group={group} />
                    </BaseGroupCard>
            })}
        </Accordion>
  </div>
}

export const GroupApp = () => {

    const [groupData, setGroupData] = useState<GroupInfos>();
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        groupService.fetchAllExtended(process.env.BASE_URL ?? "http://localhost:8080")
            .then(data => {
                setGroupData(data);
                setLoading(false);
            })
            .catch(e => {
                // TODO handle error
                console.error(e); 
                setLoading(false);
            });
    }, [])

    return <AppBody title="Gruppen" className="group-app" containerClassName="group-container">
        <BaseGroupList groups={groupData?.filter(x => x.type === 'extended-main') ?? []} />
        <BaseGroupList groups={groupData?.filter(x => x.type === 'extended-sub') ?? []} />
    </AppBody>
}

export default GroupApp;