import React, { useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import groupService, { GroupInfo, GroupMemberInfo } from '../../services/group.service';
import LoadingModal from '../utilities/LoadingModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

export const GroupMembersPopup : React.FC<{currentGroup : GroupInfo, show : boolean, setShow: (show : boolean) => void}> = ({currentGroup, show, setShow}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [memberData, setMemberData] = useState<GroupMemberInfo[]>([])
    const [isEditable, setEditable] = useState<boolean>(!(currentGroup.type === 'extended-main' || currentGroup.type === 'main'));

    useEffect(() => {
        setLoading(true);
        if (currentGroup.type === 'extended-main' || currentGroup.type === 'main') {
            groupService.fetchMemberByMainGroup(process.env.BASE_URL ?? "http://localhost:8080", currentGroup.data.id)
                .then(x => {
                    const sort = x.sort((a, b) => `${a.name.toLowerCase()}${a.firstname.toLowerCase()}`.localeCompare( `${b.name.toLowerCase()}${b.firstname.toLowerCase()}`));
                    setMemberData(sort);
                    setLoading(false);
                })
                .catch(e => {
                    setLoading(false);
                    console.error(e);
                });
        } else {
            groupService.fetchMemberBySubGroup(process.env.BASE_URL ?? "http://localhost:8080", currentGroup.data.id)
                .then(x => {
                    const sort = x.sort((a, b) => `${a.name.toLowerCase()}${a.firstname.toLowerCase()}`.localeCompare( `${b.name.toLowerCase()}${b.firstname.toLowerCase()}`));
                    setMemberData(sort);
                    setLoading(false);
                })
                .catch(e => {
                    setLoading(false);
                    console.error(e);
                });
        }
    }, [currentGroup]);

    function addMemberToGroup() {

    }

    function removeMemberFromGroup(memberId : number) {
        setLoading(true);
        if (currentGroup.type === 'sub' || currentGroup.type === 'extended-sub') {
            groupService.removeMembersFromSubGroup(process.env.BASE_URL ?? "http://localhost:8080", currentGroup.data.id, [memberId])
                .then(x => {
                    setLoading(false);
                    setMemberData(oldData => oldData.filter(y => y.id !== memberId))
                })
                .catch(e => {
                    setLoading(false);
                    console.error(e);
                });
        }
    }

    return <LoadingModal title={isEditable ? "Mitglieder bearbeiten" : "Mitglieder"} isLoading={isLoading} setShow={setShow} show={show}>
        <ListGroup className="scrolbar-style" style={{maxHeight: '70vh', overflowY: 'auto'}}>
            {memberData.length > 0 && <>
                {isEditable && <ListGroup.Item style={{color: '#000'}} action={!isLoading} onClick={() => addMemberToGroup()}>
                    <FontAwesomeIcon icon={faUserPlus} color="black"/> Mitglied Hinzufügen 
                </ListGroup.Item>}
                {memberData.map(member => {
                    return <ListGroup.Item key={`group-member-${member.id}`} style={{color: '#000'}} className="d-flex align-items-center justify-content-between">
                        <span>{member.name}, {member.firstname}</span>
                        {isEditable && <Button variant="danger" size={'sm'} disabled={isLoading} onClick={() => removeMemberFromGroup(member.id)}><FontAwesomeIcon icon={faTimes} color="white" /></Button>}
                    </ListGroup.Item>
                })}
            </>}
            {isEditable && memberData.length === 0 && <ListGroup.Item style={{color: '#000'}} action={!isLoading} onClick={() => addMemberToGroup()}>
                <FontAwesomeIcon icon={faUserPlus} color="black"/> Mitglied Hinzufügen 
            </ListGroup.Item>}
        </ListGroup>
    </LoadingModal>
}

export default GroupMembersPopup;