import React, { useEffect, useState } from 'react';
import memberService, { Member } from '../services/member.service';

export interface SelectedMemberContextState {
    selectedMember : Member | null;
    setSelectedMember: (member : Member) => void
    update: (member : Member) => Promise<void>;
}

const SelectedMemberContext = React.createContext<SelectedMemberContextState>({} as SelectedMemberContextState);

const SelectedMemberProvider : React.FC<{initialMember? : Member}> = ({children, initialMember}) => {
    const [selectedMember, setSelectedMember] = useState<Member|null>(initialMember ?? null); 

    useEffect(() => {
        if (selectedMember != null) {
            document.title = selectedMember.name + ", " + selectedMember.firstname;
        }
    }, [selectedMember])

    const updateMember = (member : Member) => {
        return memberService.update(`http://localhost:8080`, member)
            .then(x => {
                setSelectedMember(member);
            });
    }

    return <SelectedMemberContext.Provider value={{selectedMember, setSelectedMember, update: updateMember}}>
        {children}
    </SelectedMemberContext.Provider>
}

export const useSelectedMember = () => {
    return React.useContext(SelectedMemberContext);
}

export default SelectedMemberProvider;