import React from 'react';

interface AppBodyProps {
    title : string;
    className? : string;
    containerClassName? : string;
}

export const AppBody : React.FC<AppBodyProps> = ({children, title, className, containerClassName}) => {
    return <div className={"app-body " + (className ?? '')}>
        <div className="headline">{title}</div>
        <div className={"app-container " + (containerClassName ?? '')}>
            {children}
        </div>
    </div>
}

export default AppBody;

