import React, { useEffect, useState } from 'react';
import groupService, { GroupResult } from '../../services/group.service';
import { Member } from '../../services/member.service';
import LoadingModal from '../utilities/LoadingModal';
import { ManageMemberMainGroup } from './ManageMemberMainGroup';
import { ManageMemberSubGroups } from './ManageMemberSubGroups';

export const MemberGroupsPopup : React.FC<{currentMember : Member, show : boolean, setShow: (show : boolean) => void}> = ({show, setShow, currentMember}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [groupData, setGroupData] = useState<GroupResult | null>(null);

    useEffect(() => {
        setLoading(true);
        groupService.fetchAll(process.env.BASE_URL ?? "http://localhost:8080")
            .then(x => {
                setGroupData({...x, subGroups: x.subGroups.filter(x => currentMember.subGroups?.findIndex(y => y.id === x.id) === -1)});
                setLoading(false);
            })
            .catch(e => console.error(e));
    }, [currentMember]);

    return <>
        <LoadingModal title="Gruppen bearbeiten" isLoading={isLoading} setShow={setShow} show={show}>
            <div className="edit-groups">
                <ManageMemberMainGroup 
                    currentMember={currentMember} 
                    isLoading={isLoading} 
                    setLoading={setLoading} 
                    groupData={groupData} />

                <ManageMemberSubGroups 
                    currentMember={currentMember} 
                    isLoading={isLoading} 
                    setLoading={setLoading} 
                    groupData={groupData} />
            </div>
        </LoadingModal>
    </>
}

export default MemberGroupsPopup;