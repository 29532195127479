import React, { useState } from 'react';
import { useSelectedMember } from '../../provider/selected-member.provider';
import groupService, { GroupResult, SubGroup } from '../../services/group.service';
import { Member } from '../../services/member.service';

interface ManageSubGroupsProps {
    currentMember : Member;
    groupData : GroupResult | null;
    setLoading : (isLoading : boolean) => void;
    isLoading : boolean;
}

export const ManageMemberSubGroups : React.FC<ManageSubGroupsProps> = ({groupData, currentMember, setLoading}) => {
    const selectedMemberContext = useSelectedMember();
    const [selectedSubGroup, setSelectedSubGroup] = useState<SubGroup | null>(null);
    const [tempSubGroup, setTempSubGroup] = useState<boolean>(false);

    const saveSubGroup = (member : Member, selected : SubGroup) => {
        setLoading(true);

        groupService.addSubGroup(process.env.BASE_URL ?? "http://localhost:8080", member.id, [selected])
            .then(x => {
                const newMember : Member = {...member, subGroups: [...member.subGroups ?? [], selected]};
                selectedMemberContext.update(newMember);
                setSelectedSubGroup(null);
                setTempSubGroup(false);
                setLoading(false);
            })
            .catch(e => console.error(e));
    }

    const removeSubGroup = (member : Member, group : SubGroup) => {
        setLoading(true);

        groupService.removeSubGroup(process.env.BASE_URL ?? "http://localhost:8080", member.id, [group])
            .then(x => {
                const updateSubGroups = [...member.subGroups ?? []].filter(x => x.id !== group.id);
                const newMember : Member = {...member, subGroups: updateSubGroups };
                selectedMemberContext.update(newMember);
                setSelectedSubGroup(null);
                setTempSubGroup(false);
                setLoading(false);
            })
            .catch(e => console.error(e));
    }

    const addSubGroup = React.useCallback(() => {
        setTempSubGroup(true); 
        setSelectedSubGroup(groupData?.subGroups[0] ?? null)
    }, [groupData]);

    return <div className="sub-groups">
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">Untergruppen</span>
                </div>
                <ul className="list-group flex-grow-1">
                    {currentMember.subGroups?.map(sub => {
                        return <li key={sub.id} className="list-group-item">
                            <div className="d-flex justify-content-between">
                                {sub.name}<span className="remove" onClick={() => removeSubGroup(currentMember, sub)}>X</span>
                            </div>
                        </li>
                    })}
                    {tempSubGroup && <li className="list-group-item">
                        <div className="add-group">
                            <div className="input-group">
                                <select className="form-control" onChange={(e) => setSelectedSubGroup(groupData?.subGroups.find(x => x.id.toString() === e.target.value) ?? null)}>
                                    {groupData?.subGroups.map((group, idx) => {
                                        return <option key={group.id} value={group.id} selected={idx === 0}>{group.name}</option>;
                                    })}
                                </select>
                                {selectedSubGroup != null && <><div className="input-group-append">
                                    <button className="btn btn-success" type="button" onClick={() => saveSubGroup(currentMember, selectedSubGroup)}>Speichern</button>
                                </div>
                                <div className="input-group-append">
                                    <button className="btn btn-danger" onClick={() => setTempSubGroup(false)} type="button">X</button>
                                </div></>}
                            </div>
                        </div>
                    </li>}
                    {!tempSubGroup && (groupData?.subGroups.length ?? 0) > 0 && <li className="list-group-item">
                        <span className="btn btn-sm btn-success" title="Gruppe hinzufügen" onClick={() => addSubGroup()}>+</span>
                    </li>}
                </ul>
            </div>
        </div>
}