import axios from "axios";

export type GroupInfo = 
    { type: 'sub', data: SubGroup } | 
    { type: 'main', data: MainGroup } | 
    { type: 'extended-sub', data: ExtendedSubGroup } | 
    { type: 'extended-main', data: ExtendedMainGroup } 

export type GroupInfos = GroupInfo[];

export interface BaseGroupInfo {
    id : number;
    name : string;
}

export interface ExtendedBaseGroupInfo {
    memberCount: number;
}

export interface MainGroup extends BaseGroupInfo {
    color : string;
}

export interface SubGroup extends BaseGroupInfo {
}

export type MainGroups = MainGroup[];
export type SubGroups = SubGroup[];

export interface GroupResult {
    mainGroups : MainGroups;
    subGroups : SubGroups;
}

export interface ExtendedMainGroup extends ExtendedBaseGroupInfo, MainGroup {
}

export interface ExtendedSubGroup extends ExtendedBaseGroupInfo, SubGroup {
}

export type ExtendedMainGroups = ExtendedMainGroup[];
export type ExtendedSubGroups = ExtendedSubGroup[];

export interface ExtendedGroupResult {
    mainGroups : ExtendedMainGroups;
    subGroups : ExtendedSubGroups;
}

export interface GroupMemberInfo {
    id : number;
    firstname : string;
    name : string;
}

export interface GroupService {
    fetchAll : (baseUrl : string) => Promise<GroupResult>
    fetchAllExtended : (baseUrl : string) => Promise<GroupInfos>
    updateMemberMainGroup : (baseUrl : string, memberId : number, mainGroup : MainGroup) => Promise<void>
    updateMainGroup : (baseUrl : string, mainGroup : MainGroup) => Promise<void>
    addSubGroup : (baseUrl : string, memberId : number, subGroups : SubGroups) => Promise<void>
    removeSubGroup : (baseUrl : string, memberId : number, subGroups : SubGroups) => Promise<void>
    addMembersToSubGroup : (baseUrl : string, groupId : number, memberIds : number[]) => Promise<void>
    removeMembersFromSubGroup : (baseUrl : string, groupId : number, memberIds : number[]) => Promise<void>
    fetchMemberBySubGroup : (baseUrl : string, groupId : number) => Promise<GroupMemberInfo[]>
    fetchMemberByMainGroup : (baseUrl : string, groupId : number) => Promise<GroupMemberInfo[]>
}

class HttpGroupService implements GroupService {
    public fetchAll(baseUrl : string) : Promise<GroupResult> {
        return axios.get<GroupResult>(`${baseUrl}/v1/groups`)
            .then(x => x.data);
    }

    public fetchAllExtended(baseUrl : string) : Promise<GroupInfos> {
        return axios.get<ExtendedGroupResult>(`${baseUrl}/v1/groups`, {params: { details: 'extended' }})
            .then(x => {
                const groupInfos : GroupInfos = [];
                groupInfos.push(...x.data.mainGroups.map<GroupInfo>(g => { return { type: 'extended-main', data: g } }));
                groupInfos.push(...x.data.subGroups.map<GroupInfo>(g => { return { type: 'extended-sub', data: g } }));
                return groupInfos;
            });
    }

    public updateMemberMainGroup(baseUrl : string, memberId : number, mainGroup : MainGroup) {
        return axios.put<void>(`${baseUrl}/v1/members/${memberId}/main-group`, JSON.stringify(mainGroup), {
                headers: { 'Content-Type': 'application/json' }
            })
            .then(x => x.data);
    }
    
    public addSubGroup(baseUrl : string, memberId : number, subGroups : SubGroups) {
        return axios.put<void>(`${baseUrl}/v1/members/${memberId}/sub-groups`, JSON.stringify(subGroups), {
            headers: { 'Content-Type': 'application/json' }
        })
        .then(x => x.data);
    }
    
    public removeSubGroup(baseUrl : string, memberId : number, subGroups : SubGroups) {
        return axios.delete<void>(`${baseUrl}/v1/members/${memberId}/sub-groups`, {
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(subGroups)
        })
        .then(x => x.data);
    }
    
    public addMembersToSubGroup(baseUrl : string, groupId : number, memberIds : number[]) {
        return axios.put<void>(`${baseUrl}/v1/groups/sub-groups/${groupId}/members`, JSON.stringify(memberIds), {
            headers: { 'Content-Type': 'application/json' },
        })
        .then(x => x.data);
    }
    
    public removeMembersFromSubGroup(baseUrl : string, groupId : number, memberIds : number[]) {
        return axios.delete<void>(`${baseUrl}/v1/groups/sub-groups/${groupId}/members`, {
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(memberIds)
        })
        .then(x => x.data);
    }
    
    public updateMainGroup(baseUrl : string, mainGroup : MainGroup) {
        return axios.put<void>(`${baseUrl}/v1/groups/main-groups/${mainGroup.id}`, JSON.stringify(mainGroup), {
                headers: { 'Content-Type': 'application/json' }
            })
            .then(x => x.data);
    }

    public fetchMemberBySubGroup(baseUrl : string, groupId : number) {
        return axios.get<GroupMemberInfo[]>(`${baseUrl}/v1/groups/sub-groups/${groupId}/members`)
            .then(x => x.data);
    }

    public fetchMemberByMainGroup(baseUrl : string, groupId : number) {
        return axios.get<GroupMemberInfo[]>(`${baseUrl}/v1/groups/main-groups/${groupId}/members`)
            .then(x => x.data);
    }
}

const groupService = new HttpGroupService();
export default groupService;