import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import RGL, { WidthProvider } from "react-grid-layout";
import { useParams } from 'react-router';
import { hasActiveG26 } from '../../helpers/member.helper';
import { useOverlay } from '../../provider/overlay.provider';
import SelectedMemberProvider, { useSelectedMember } from '../../provider/selected-member.provider';
import memberService, { Member } from '../../services/member.service';
import AppBody from '../AppBody';
import { MemberGroupsPopup } from '../groups';

const ReactGridLayout = WidthProvider(RGL);

export const MemberApp : React.FunctionComponent<{initalMemberId? : number}> = ({initalMemberId}) => {
    const selectedMemberContext = useSelectedMember();
    const searchRef = useRef<HTMLInputElement>(null);
    const [elRefs, setElRefs] = useState<React.RefObject<HTMLLIElement>[]>([]);
    const [memberData, setMemberData] = useState<Member[]>([]);
    const [searchMemberData, setSearchMemberData] = useState<Member[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    useEffect(() => {
        if (searchQuery !== '') {
            const search = searchQuery.toLowerCase();
            const data = memberData.filter(member => member.name.toLowerCase().indexOf(search) >= 0 || member.firstname.toLowerCase().indexOf(search) >= 0)
                                   .sort((x, y) => x.name.localeCompare(y.name));
            setSearchMemberData(data);
            selectedMemberContext.setSelectedMember(data[0]);

        } else {
            setSearchMemberData([]);
        }
    }, [searchQuery, memberData, selectedMemberContext]);

    useEffect(() => {

        memberService.all(process.env.BASE_URL ?? "http://localhost:8080")
        .then(x => {
            const refs : React.RefObject<HTMLLIElement>[] = [];
            x.forEach((member) => { refs[member.id] = React.createRef<HTMLLIElement>()});
            setElRefs(refs);
            setMemberData(x);
            selectedMemberContext.setSelectedMember(x[initalMemberId ?? 0])
        }).catch(e => console.error(e));

        const globalKeyup = (e : KeyboardEvent) => {
            if (e.ctrlKey && e.key === 'f') {
                e.preventDefault();
                e.stopPropagation();
                searchRef.current?.focus();
            }
        }

        document.addEventListener('keydown', globalKeyup);

        return () => {
            document.removeEventListener('keydown', globalKeyup);
        }
    }, [initalMemberId])

    const shownData = memberData.length > 0 && searchMemberData.length > 0 ? searchMemberData : memberData;

    const selectionOneUp = () => {
        const currentSelectionIndex = shownData.findIndex(x => x.id === selectedMemberContext.selectedMember?.id);
        var newSelectionIndex = currentSelectionIndex;
        if (shownData.length > 0) {
            if (currentSelectionIndex > 0) {
                // eins niedriger
                newSelectionIndex = currentSelectionIndex - 1;
            } else if (currentSelectionIndex === 0) {
                // springe zum letzten
                newSelectionIndex = shownData.length - 1;
            }
            const newSelection = shownData[newSelectionIndex];
            if (elRefs[newSelection.id]) {
                elRefs[newSelection.id].current?.scrollIntoView({block: 'end'});
            }
            selectedMemberContext.setSelectedMember(newSelection);
        }
    }

    const selectionOneDown = () => {
        const currentSelectionIndex = shownData.findIndex(x => x.id === selectedMemberContext.selectedMember?.id);
        var newSelectionIndex = currentSelectionIndex;
        if (shownData.length > 0) {
            if (currentSelectionIndex < (shownData.length - 1)) {
                // eins höher
                newSelectionIndex = currentSelectionIndex + 1;
            } else if (currentSelectionIndex === (shownData.length - 1)) {
                // springe zum ersten
                newSelectionIndex = 0;
            }
            const newSelection = shownData[newSelectionIndex];
            if (elRefs[newSelection.id]) {
                elRefs[newSelection.id].current?.scrollIntoView({block: 'end'});
            }
            selectedMemberContext.setSelectedMember(newSelection);
        }
    }

    const keyupHandler = (e : React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown') {
            selectionOneDown();
        } else if (e.key === 'ArrowUp') {
            selectionOneUp();
        } else if (e.key === 'Escape') {
            setSearchQuery('');
        }
    }

    return <AppBody title="Mitglieder Verwalten" className="member-app" containerClassName="member-container">
            <div className="member-overview">
                <div className="member-list card">
                    <div className="card-header no-padding no-border-bottom">
                        <div className="input-group member-search">
                            <div className="input-group-prepend">
                                <span className="input-group-text">Suche</span>
                            </div>
                            <input ref={searchRef} className="form-control" placeholder="Max Mustermann" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} onKeyUp={keyupHandler} />
                            <div className="input-group-append" id="button-addon4">
                                <button className="btn btn-danger" type="button" disabled={searchQuery === ''} onClick={() => setSearchQuery('')}>X</button>
                            </div>
                        </div>
                    </div>
                    <ul className="list-group list-group-flush border scrolbar-style">
                        {shownData.length > 0 && shownData.map((member, i) => {
                            return <li className={"list-group-item " + 'member' + (selectedMemberContext.selectedMember?.id === member.id ? ' selected' : '')} 
                                ref={elRefs[member.id]}
                                key={member.id}
                                onClick={() => selectedMemberContext.setSelectedMember(member)}>
                                {member.firstname} {member.name} ({member.dienstgrad.shortName})
                            </li>
                        })}
                    </ul>
                    <div className="card-footer">
                        <a href="#" className="btn btn-success" title="Hinzufügen">+</a>
                        <a href="#" className="btn btn-danger ml-1" title="Entfernen">-</a>
                        <a href="#" className="btn btn-warning ml-1">Import</a>
                        <a href="#" className="btn btn-warning ml-1">Export Alle</a>
                    </div>
                </div>
            </div>
            {selectedMemberContext.selectedMember != null && <div className="member-details container-fluid container-without ">
                {<ProfileInformation />}
            </div>}
    </AppBody>
}
const originalLayouts = getFromLS("layouts") || {};

const ProfileInformation = () => {
    const {isFocusOnElement, isHidden} = useOverlay();
    const [layouts, setLayouts] = useState<RGL.Layout[]>(JSON.parse(JSON.stringify(originalLayouts)).layouts ?? []);

    const onLayoutChange = (layout : ReactGridLayout.Layout[]) => {
        saveToLS("layouts", layout);
        setLayouts(layout);
    }
    
    return <ReactGridLayout
                className="layout profile-container"
                rowHeight={30}
                onLayoutChange={onLayoutChange}
                isDraggable={isHidden}
                layout={layouts}
            >
                <div key="1" data-grid={{ x: 0, y: 0, minW: 4, w: 4, h: 8, minH: 8, static:true }}>
                    <ProfileImage />
                </div>
                <div key="2" data-grid={{ x: 0, y: 8, minW: 4, w: 4, h:5, minH: 5 }}>
                    <ProfileLehrgange />
                </div>
                <div key="3" data-grid={{ x: 4, y: 0, minW: 5, w: 5, h: 19, minH: 5}} className={(isFocusOnElement('profile-infos') ? "inline-overlay-focus-element" : "")}>
                    <ProfileInfos />
                </div>
                <div key="4" data-grid={{x: 9, y: 0, minW: 3, w: 3, h: 19, minH: 5}}>
                    <ProfileActivity />
                </div>
            </ReactGridLayout>
}

const ProfileImage = () => {

    const [editGroup, setEditGroup] = useState<boolean>(false);

    const selectedMemberContext = useSelectedMember();
    const member = selectedMemberContext.selectedMember;
    return <>
        <div className="card h-100">
            <div className="card-body">
                <div className="d-flex flex-column align-items-center text-center">
                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" className={"rounded-circle " + (hasActiveG26(member!) ? 'g26-active-border' : 'g26-notactive-border')} width="150" />
                    <div className="mt-3">
                        <h4>{member?.firstname} {member?.name}</h4>
                        <p className="text-secondary mb-1">{member?.dienstgrad.longName}</p>
                        <p className="btn badge badge-success mb-1 mr-1" onClick={() => setEditGroup(true)}>{member?.mainGroup.name}</p>
                        {member?.subGroups?.map(sub => {
                            return <p key={sub.id} className="btn badge badge-info mb-1 mr-1" onClick={() => setEditGroup(true)}>{sub.name}</p>
                        })}
                        <p className="badge badge-dark mb-1 mr-1 btn" title="Gruppe hinzufügen" onClick={() => setEditGroup(true)}>+</p>
                    </div>
                </div>
            </div>
        </div>
        {editGroup && member && <MemberGroupsPopup currentMember={member} show={editGroup} setShow={setEditGroup} />}
    </>
}

const ProfileLehrgange = () => {
    return <>
        <div className="card">
            <div className="card-header">Lehrgänge</div>
            <ul className="list-group list-group-flush lehrgang-body scrolbar-style">
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2020] Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2020] Lehrgang 2
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2020] Lehrgang 3
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 4
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 5
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 6
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 7
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 3
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 4
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 5
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 6
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 7
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 3
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 4
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 5
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 6
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    Lehrgang 7
                </li>
            </ul>
        </div>
    </>
}

const ProfileInfos = () => {
    const { setFocusedElement } = useOverlay();
    const selectedMemberContext = useSelectedMember();
    const member = selectedMemberContext.selectedMember;
    const [tempEditData, setTempEditData] = useState<Member | null>(null);
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    function onChange<T>(transform : (member : Member, changeValue : T) => Member) : (value : T) => void {    
        return (value : T) => {
            setTempEditData(tempData => transform(tempData!, value));
        }
    }

    const enableEditMode = () => {
        setFocusedElement('profile-infos');
        setEditMode(true);
        setTempEditData(member);
    }

    const disableEditMode = async (save : boolean) => {
        setLoading(true);

        if (save) {
            try {
                await selectedMemberContext.update(tempEditData!);
            } catch (e) {
                console.error(e);
            }
        }
        setFocusedElement('');
        setEditMode(false);
        // TODO save

        setTempEditData(null);
        setLoading(false);
    }

    return <>
        <div className={"card"}>
            <div className="card-header">Allgemeine Informationen</div>
            {!isLoading && <div className="card-body details-body scrolbar-style">
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.name = x; return me; })} rowTitle="Name" value={member?.name ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.firstname = x; return me; })} rowTitle="Vorname" value={member?.firstname ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.email = x; return me; })} rowTitle="Email" value={member?.email ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<Date>((me, x) => { me.birthdate = x; return me; })} rowTitle="Geburtstag" value={member?.birthdate ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.telephoneMobile = x; return me; })} rowTitle="Mobil-Nummer" value={member?.telephoneMobile ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.telephonePrivate = x; return me; })} rowTitle="Festnetz-Nummer" value={member?.telephonePrivate ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.telephoneBusiness = x; return me; })} rowTitle="Geschäfts-Nummer" value={member?.telephoneBusiness ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.anschrift.strasse = x; return me; })} rowTitle="Straße" value={member?.anschrift.strasse ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.anschrift.hausnummer = x; return me; })} rowTitle="Hausnummer" value={member?.anschrift.hausnummer ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.anschrift.plz = x; return me; })} rowTitle="Plz." value={member?.anschrift.plz ?? ''} isEditing={isEditMode} horizonalLine />
                <MemberDetailsRow onChange={onChange<string>((me, x) => { me.anschrift.ort = x; return me; })} rowTitle="Ort" value={member?.anschrift.ort ?? ''} isEditing={isEditMode} />
            </div>}
            <div className="card-footer">
                {!isLoading && !isEditMode && <button className="btn btn-success" onClick={() => enableEditMode()}>Bearbeiten</button>}
                {!isLoading && isEditMode && <button className="btn btn-success" onClick={() => disableEditMode(true)}>Speichern</button>}
                {!isLoading && isEditMode && <button className="btn btn-info ml-1" onClick={() => disableEditMode(false)}>Abbrechen</button>}
                {isLoading && <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button>}
            </div>
        </div>
    </>
}

const ProfileActivity : React.FunctionComponent<{}> = () => {
    return <>
        <div className="card">
            <div className="card-header">Aktivität</div>
            <ul className="list-group list-group-flush activity-body scrolbar-style">
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Anmeldung Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Anmeldebestätigung Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Erfolgreicher Abschluss Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Teilnahme Übungsdienst
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Absage Übungsdiens
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Anmeldung Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Anmeldebestätigung Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Erfolgreicher Abschluss Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Teilnahme Übungsdienst
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Absage Übungsdiens
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Anmeldung Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Anmeldebestätigung Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Erfolgreicher Abschluss Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Teilnahme Übungsdienst
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Absage Übungsdiens
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Anmeldung Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Anmeldebestätigung Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Erfolgreicher Abschluss Lehrgang 1
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Teilnahme Übungsdienst
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    [01.01.2021] Absage Übungsdiens
                </li>
            </ul>
        </div>
    </>
}

const EditField : React.FunctionComponent<{value? : string | number | Date, setTempValue: (val : string | number | Date) => void}> = ({value, setTempValue}) => {
    if (typeof(value) === 'number') {
        if (value == null)
            console.log(value)
        return <input type="number" value={value} onChange={e => setTempValue(e.target.value)} />
    } else if (value instanceof Date) {
        const date = moment(value);
        //const date = tempValue != null && tempValue instanceof Date ? moment(tempValue) : moment(value);
        return <input type="date" value={date.format("YYYY-MM-DD")} onChange={e => setTempValue(new Date(e.target.value))} />
    } else {
        //const val = tempValue != null ? tempValue.toString() : value?.toString();
        if (value == null)
            console.log("Text: "+value)
        return <input type="text" value={value} onChange={e => setTempValue(e.target.value)} />
    }
}

const MemberDetailsRow: React.FunctionComponent<{ rowTitle: string, value?: any, onChange : (value : any) => void, horizonalLine?: boolean, isEditing?: boolean }> = ({ rowTitle, onChange, value, horizonalLine, isEditing }) => {
    
    const [tempValue, setTempValue] = useState<string | number | Date | null>(null);

    useEffect(() => {
        if (tempValue != null) {
            onChange(tempValue);
        }
    }, [tempValue])

    const Value = () => {
        if (value instanceof Date) {
            return <>{value.toLocaleDateString()}</>
        } else {
            return <>{value}</>
        }
    }

    return <>
        {!isEditing && value && <div className="row">
            <div className="col-sm-4">
                <h6 className="mb-0">{rowTitle}</h6>
            </div>
            <div className="col-sm-8 text-secondary">
                <Value />
            </div>
        </div>}
        {isEditing && <div className="row">
            <div className="col-sm-4">
                <h6 className="mb-0">{rowTitle}</h6>
            </div>
            <div className="col-sm-8 text-secondary">
                {<EditField value={tempValue != null ? tempValue : value} setTempValue={setTempValue} />}
            </div>
        </div>}
        {!isEditing && value && horizonalLine && <hr />}
        {isEditing && horizonalLine && <hr />}
    </>
}

function getFromLS(key:string) : {} {
    let ls : any = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage?.getItem("rgl-8") as string) || {};
      } catch (e) {
      }
    }
    return ls[key];
  }
  
  function saveToLS(key : string, value : {}) {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

const WrappedMemberApp : React.FunctionComponent<{}> = () => {
    const {id} = useParams<{id : string}>();
    return <SelectedMemberProvider>
        <MemberApp initalMemberId={Number.parseInt(id)} />
    </SelectedMemberProvider>
}

export default WrappedMemberApp;