import axios from "axios";
import { MainGroup, SubGroups } from "./group.service";

export interface Member {
    id: number,
    name: string;
    firstname: string;
    email: string;
    birthdate: Date;
    telephonePrivate?: string;
    telephoneMobile?: string;
    telephoneBusiness?: string;
    dienstgrad: {
        id: string,
        shortName: string;
        longName: string;
    };
    anschrift: {
        strasse: string;
        hausnummer: string;
        plz: string;
        ort: string;
    },
    validG26Date?: Date;
    mainGroup: MainGroup;
    subGroups?: SubGroups;
}

export interface MemberService {
    all(baseUrl : string) : Promise<Member[]>;
    update(baseUrl : string, memberData: Member) : Promise<void>;
}

class HttpMemberService implements MemberService {
    public all(baseUrl : string) {
        return axios.get<Member[]>(`${baseUrl}/v1/members`)
            .then(x => {
                const result : Member[] = [];

                x.data.forEach(member => {
                    result.push({
                        ...member, 
                        birthdate: new Date(member.birthdate), 
                        validG26Date: member.validG26Date ? new Date(member.validG26Date) : undefined
                    });
                })
                
                return result;
            });
    }

    public update(baseUrl : string, memberData : Member) {
        return axios.put<void>(`${baseUrl}/v1/members/${memberData.id}`, JSON.stringify(memberData), {headers: {
            'Content-Type': 'application/json',
        }}).then(x => x.data);
    }
}

const memberService = new HttpMemberService();
export default memberService;