import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface LoadingModalProps {
  title: string;
  show? : boolean;
  isLoading : boolean;
  setShow: (show : boolean) => void;
}

export const LoadingModal : React.FC<LoadingModalProps> = ({title, children, isLoading, show, setShow}) => {
    const handleClose = () => setShow(false);

    return <Modal show={show} backdrop={isLoading ? 'static' : true} onHide={handleClose} >
              <Modal.Header closeButton={!isLoading}>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {children}
              </Modal.Body>
              <Modal.Footer>
                {!isLoading && <Button variant="secondary" onClick={handleClose}>
                    Abbrechen
                </Button>}
                {isLoading && <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button>}
              </Modal.Footer>
            </Modal>
}

export default LoadingModal;

