import React, { useState } from 'react';

interface OverlayProviderContext {
    isHidden : boolean;
    setFocusedElement: (element : string) => void;
    isFocusOnElement: (element : string) => boolean;
}

const OverlayContext = React.createContext<OverlayProviderContext>({} as OverlayProviderContext);

const OverlayProvider : React.FC<{}> = ({children}) => {
    const [focusedElement, setFocusedElement] = useState<string>('');

    const isFocusOnElement = (element : string) : boolean => {
        return focusedElement === element;
    }

    return <OverlayContext.Provider value={{isHidden: focusedElement === '', setFocusedElement, isFocusOnElement}}>
        {children}
    </OverlayContext.Provider>
}

export const useOverlay = () => {
    return React.useContext(OverlayContext);
}

export default OverlayProvider;