import React, { useState } from 'react';
import { useSelectedMember } from '../../provider/selected-member.provider';
import groupService, { GroupResult, MainGroup } from '../../services/group.service';
import { Member } from '../../services/member.service';

interface ManageMainGroupProps {
    currentMember : Member;
    groupData : GroupResult | null;
    setLoading : (isLoading : boolean) => void;
    isLoading : boolean;
}

export const ManageMemberMainGroup : React.FC<ManageMainGroupProps> = ({groupData, currentMember, setLoading}) => {
    const selectedMemberContext = useSelectedMember();
    const [selectedMainGroup, setSelectedMainGroup] = useState<MainGroup | null>(null);

    const saveMainGroup = (member : Member, selected : MainGroup) => {
        setLoading(true);

        groupService.updateMemberMainGroup(process.env.BASE_URL ?? "http://localhost:8080", member.id, selected)
            .then(x => {
                const newMember : Member = {...member, mainGroup: selected};
                selectedMemberContext.update(newMember);
                setSelectedMainGroup(null);
                setLoading(false);
            })
            .catch(e => console.error(e));
    }

    return <div className="main-group">
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <span className="input-group-text">Hauptgruppe</span>
            </div>
            <select className="form-control" onChange={(e) => setSelectedMainGroup(groupData?.mainGroups.find(x => x.id.toString() === e.target.value) ?? null)}>
                {groupData?.mainGroups.map(group => {
                    return <option key={group.id} value={group.id} selected={selectedMainGroup != null ? group.id === selectedMainGroup.id : group.id === currentMember.mainGroup.id}>{group.name}</option>;
                })}
            </select>
            {selectedMainGroup != null && selectedMainGroup?.id !== currentMember.mainGroup.id && <div className="input-group-append">
                <button className="btn btn-success" type="button" onClick={() => saveMainGroup(currentMember, selectedMainGroup)}>Speichern</button>
            </div>}
            {selectedMainGroup != null && selectedMainGroup?.id !== currentMember.mainGroup.id && <div className="input-group-append">
                <button className="btn btn-danger" onClick={() => setSelectedMainGroup(null)} type="button">X</button>
            </div>}
        </div>
    </div>
}